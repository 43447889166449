<!-- 房价管理 -->
<template>
  <section class="cont roomCalendar">
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>PMS</el-breadcrumb-item>
          <el-breadcrumb-item>房价设置</el-breadcrumb-item>
          <el-breadcrumb-item>房价管理</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>

    <el-row class="content-box">
      <span style="padding-right: 16px;color: #adbfdd;">系统</span>
      <el-button :class="{'bg-gradient':item.value===active}" style="margin-bottom: 20px;" v-for="item in channel" :key="item.value" @click="selectChannel(item.value)">{{item.label}}</el-button>
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-col :lg="{span:10}">
            <span class="el-date-editor--prevquantum" @click="changeDate('-')">
              <i class="el-icon-arrow-left"></i>
            </span>
            <el-date-picker value-format="yyyy-MM-dd" :clearable="false" class="width-140" @change="getPrice" :editable="false" v-model="beginTime" type="date" :picker-options="pickerOptions"/>
            <span class="el-date-editor--nextquantum" @click="changeDate('+')">
              <i class="el-icon-arrow-right"></i>
            </span>
            <el-select v-model="roomType" placeholder="请选择" style="margin-left: 8px;" @change="changeRoomType">
              <el-option label="全部本地房型" value=""></el-option>
              <el-option v-for="item in roomTypeList" :key="item.id" :label="item.roomTypeName" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <!-- <el-row class="right-btn" style="line-height: 40px;">
            <el-switch v-model="show"></el-switch> <span style="margin-left: 8px;margin-right: 16px;">{{ show?"显示库存":"隐藏库存" }} </span>
          </el-row> -->
        </div>
        <!-- 一周房价信息 -->
        <el-table :data="tableData.roomTypeList" border fit :stripe="true" :cell-style="columnStyle" :header-cell-style="tableHeaderCellStyle" style="width: 100%" v-loading="loading" @cell-click="checked">
          <el-table-column fixed prop="roomTypeName" label="本地房型" width="150"></el-table-column>
          <el-table-column :prop="item.dateString" :label="`${item.dateStr} ${item.weekName} 剩 ${item.roomStatus && item.roomStatus.roomQuantity} ${item.holidays}` " width="100" v-for="(item,index) in tableData.header" :key="index" :render-header="renderHeader">
            <template slot-scope="scope">
              <div>
                <div>
                  ￥{{ scope.row.roomPriceList[index].roomPrice}}
                </div>
                <div v-show="show" style="color: #999;font-size: 12px;">
                  剩 {{ scope.row.roomPriceList[index].roomStatus.roomQuantity}}
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>

    <!-- 右侧弹窗 修改价格 -->
    <el-drawer title="修改价格" :visible.sync="dialog" direction="rtl" custom-class="demo-drawer" ref="drawer" :before-close="handleClose" :size="460">
      <div class="demo-drawer__content">
        <el-form :model="ruleForm" ref="ruleForm" :rules="rules" style="padding: 16px 24px;" label-width="80px">
          <el-form-item label="本地房型" >
            <span style="color:#999">{{roomTypeName}}</span>
          </el-form-item>
          <el-form-item label="改价日期"  prop="time">
              <el-date-picker class="width-300" v-model="ruleForm.time" type="daterange" value-format="yyyy-MM-dd"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
              </el-date-picker>
          </el-form-item>
          <el-form-item label="门市价"  prop="roomPrice">
            <el-input class="width-300" v-model="ruleForm.roomPrice" :onkeyup='dataValidation' placeholder="如:200" clearable>
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="demo-drawer__footer footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">保 存</el-button>
        </div>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { pms } from '@/api/interface/pms';
import { dateFactory,getDict } from '@/common/js/common'
export default {
  data(){
    return {
      channel:[],              // 渠道数组
      active:'',               // 选中的渠道
      tableDataList:[],        
      currentTime:"",          // 当前时间
      hotelId:"",              // 酒店ID
      beginTime:"",            // 查询时间
      roomTypeList:[],         // 房型列表
      roomType: "",            // 选择的房型
      show: false,             // 房量显示
      tableData:[],            // 表格数据
      drawer: false,           // 右侧抽屉状态
      loading:true,            // 表格加载动画

      dialog: false,           // 抽屉显示状态
      roomTypeName:"",         // 房型名称
      roomTypeId:"",           // 房型ID
      ruleForm:{               // 表单数据
        time:[],               // 时间
        roomPrice:"",          // 价格
      },
      rules:{                  //校验规则
        time:[{ required:true, message: '请选择日期' , trigger: 'change'}],
        roomPrice:[{ required:true,message: '请填写门市价',trigger: 'blur'},],
      },

      // 日历快捷选项配置
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      prompt: '提示！',
      update_success: '修改成功！',
    }
  },
  computed: {
    ...mapState(['dictData','hotelInfo']),
    // 验证输入金额
    dataValidation(){
      return 'value=value.replace(/[^\\d.]/g,"").replace(".", "$#$").replace(/\\./g, "").replace("$#$", ".").replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, "$1$2.$3").replace(/^\\./g, "").replace(/\\.{2,}/g, ".")';
    },
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.hotelId = this.hotelInfo.id;
        this.getRoomType();
        this.getPrice();
      }
    }
  },
  mounted(){
    getDict(["order-source"],false);
    this.channel = [...this.dictData['order-source-list']];
    this.active = this.channel[0].value;
    this.hotelId = this.hotelInfo.id;
    this.beginTime = dateFactory.getDistanceToday(0,false);
    this.getRoomType();
    this.getPrice();
  },
  methods:{
    // 表头三列
    renderHeader(createElement, { column }) {
      let label = column.label;
      let labelArr = label.split(' ');
      if (this.show) {
        return createElement(
        'div',
        {'class': 'header-center'},
        [
          createElement('div',
          {
            attrs: { type: 'text' },
          }, [labelArr[0]]),
          createElement('div', {
            attrs: { type: 'text' },
          }, [labelArr[1] || '']),
          createElement('div', {
            attrs: { type: 'text' },
          }, [labelArr[2]+" "+labelArr[3] || ''])
        ]
      );
      } else {
        return createElement(
        'div',
        {'class': 'header-center'},
        [
          createElement('div',
          {
            attrs: { type: 'text' },
          }, [labelArr[0]]),
          createElement('div', {
            attrs: { type: 'text' },
          }, [labelArr[1] || ''])
        ]
      );
      }
    },
    // 表头节假日标记
    tableHeaderCellStyle({ column }) {
      let cellStyle1;
      let cellStyle2;
      cellStyle1= {color:'#f5222d',background: "#f0f3fc"};
      cellStyle2= {background: "#f0f3fc"};
      if(column.label.split(' ')[4]==="YES"){
        return cellStyle1;
      } else{
        return cellStyle2;
      }
    },
    // 列变色
    columnStyle({ column, columnIndex }) {
      if (columnIndex !== 0 && column.property < this.currentTime) {
        //字体颜色
        return "background:#ddd;cursor:not-allowed;";
      }
    },
    // 选择渠道
    selectChannel(value){
      this.active =value;
      this.getPrice();
    },
    // 获取房型列表
    getRoomType(){
      const url = pms.getRoomTypeList;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
        roomTypeSource:'PMS',
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.roomTypeList = res.records;
        }
      })
    },
    // 点击按钮触发时间改变
    changeDate(method){
      this.beginTime = dateFactory.getDistanceAppoint(this.beginTime, method, 30, false);
      this.getPrice();
    },
    // 选择房型时触发
    changeRoomType(){
      let tableDataList = this.tableDataList;
      if ( this.roomType !== "" ) {
        for (let index = 0; index < tableDataList.length; index++) {
          if (tableDataList[index].id === this.roomType) {
            let arr = [];
            arr.push(tableDataList[index])
            this.tableData.roomTypeList=arr;
          }
        }
      }else{
        this.tableData.roomTypeList = this.tableDataList;
      }
    },
    // 获取价格信息
    getPrice(){
      const url = pms.getRoomPrice;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
        beginDate:this.beginTime,                        // 开始日期
        days:29,                                         // 天数
        pmsSourceType:this.active                        // 渠道
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.tableData = res.records;
          this.tableDataList = res.records.roomTypeList;
          this.currentTime =  res.records.nowTime;
          this.loading = false;
        }
      })
    },
    // 关闭
    handleClose(){
      this.dialog = false;
    },
    // 点击事件调整价格
    checked(row,column){
      if (column.property!=="roomTypeName" && column.property >= this.currentTime) {
        this.dialog = true;
        this.roomTypeName = row.roomTypeName;
        this.ruleForm.time = [];
        this.ruleForm.time[0]=column.property;
        this.ruleForm.time[1]=column.property;
        for (let index = 0; index < row.roomPriceList.length; index++) {
          if (row.roomPriceList[index].priceDate === column.property) {
            this.ruleForm.roomPrice= row.roomPriceList[index].roomPrice;
            this.roomTypeId= row.roomPriceList[index].roomTypeId;
          }
        }
      }
    },
    // 保存
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid){
          const url = pms.setRoomPrice;
          const param = {
            hotelId:this.hotelId,                        // 酒店ID
            pmsSourceType:this.active,                   // 渠道
            beginDate:this.ruleForm.time[0],             // 开始时间
            endDate:this.ruleForm.time[1],               // 结束时间
            priceCalendarDTOS:[
              {roomTypeId:this.roomTypeId,roomPrice:this.ruleForm.roomPrice}
            ]
          }
          this.$axios.post(url, param, 'json').then((res) => {
            if (res.success) {
              this.$message({showClose: true,message: this.update_success,type: 'success'});
              this.handleClose();
              this.getPrice();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.roomCalendar{
  .right-title{
    padding: 25px 25px 25px 0;
    line-height: 40px;
  }

  .el-date-editor--prevquantum{
    display: inline-block;
    width: 32px;
    height: 40px;
    line-height: 35px;
    border: 1px solid #e0e0e0;
    border-right: 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
  }
  .el-date-editor--nextquantum{
      display: inline-block;
      width: 32px;
      height: 40px;
      line-height: 35px;
      border: 1px solid #e0e0e0;
      border-left: 0px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
  }

  /deep/.el-table thead {
    color: #333;
  }

  /deep/ .cell{
    .header-center{
      text-align: center;
      div:nth-child(3){
        font-size: 12px;
        color: #666;;
      }
    }
  }

  /deep/ .el-table__body-wrapper{
    .el-table__cell{
      text-align: center;
      &:hover{
        border: 1px solid #409eff ;
        cursor: pointer;
      }
    }
    .el-table__cell:first-child{
      &:hover{
        border: none;
      }
    }
    .el-table__cell[style="background: rgb(221, 221, 221); cursor: not-allowed;"]{
      &:hover{
        border-top: 0;
        border-left: 0;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
      }
    }
  }

  /deep/ .el-input--prefix .el-input__inner{
    border-radius: 0;
  }

  .footer{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  /deep/ .el-table th.gutter{
    /*
    * 解决element-ui 表格篡位的问题 👇
    */
    display: table-cell!important;
  }
}
</style>
